<template>
    <div class="dropdown topbar-head-dropdown ms-1 header-item">
        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="bx bx-bell fs-22"></i>
            <span v-if="notifications.length > 0"
                class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{{
                        notifications.length
                }}<span class="visually-hidden">unread messages</span></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown" style="">

            <div class="dropdown-head bg-primary bg-pattern rounded-top">
                <div class="p-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                        </div>
                        <div class="col-auto dropdown-tabs">
                            <span class="badge badge-soft-light fs-13"> {{ notifications.length }} New</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="simplebar-content">
                <div v-for="item in notifications" :key="item"
                    class="text-reset notification-item d-block dropdown-item position-relative">
                    <div class="d-flex" style="cursor: pointer;" @click="notificationAction(item)">
                        <div class="avatar-xs me-3 mt-3">
                            <span class="avatar-title bg-soft-warning text-warning rounded-circle fs-16">
                                <i class="bx bx-bell"></i>
                            </span>
                        </div>
                        <div class="flex-1">
                            <div href="" class="stretched-link">
                                <h6 class="mt-0 mb-0 lh-base">{{ item.data.title }}</h6>
                                <p class="text-muted mb-1"> {{ item.data.message }}</p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span><i class="mdi mdi-clock-outline"></i> {{ notificationTime(item.created_at)
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="my-3 text-center" v-if="notifications.length > 0">
                    <button @click="readAllNotification()" type="button"
                        class="btn btn-soft-success waves-effect waves-light">Tandai semua sudah dibaca <i
                            class="ri-check-line align-middle"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { endpoint } from '../host';
import errorService from '../services/error-service';
import httpService from '../services/http-service';
import permissonService from '../services/permisson-service';
import axios from 'axios';
export default {
    created() {
        this.getUnreadNotification();
        // this.timer = setInterval(() => {
        //     this.getUnreadNotification();
        // }, 10000);
    },
    data() {
        return {
            timer: null,
            unread_total: 0,
            notifications: [],
        };
    },
    watch: {
        $route(to, from) {
            // let paths = to.path.split("/");
            // if (paths[1] != 'admin' && paths[1] != 'user') {
            //     clearInterval(this.timer);
            // } else {
            //     if (paths[2] == "login" || paths[2] == "register") {
            //         clearInterval(this.timer);
            //     }
            // }
            this.getUnreadNotification();
        }
    },
    methods: {
        async getUnreadNotification() {
            try {
                // let res = await httpService.get(endpoint.notification + '/unread');
                let res = await axios.get(endpoint.notification + '/unread', {
                    headers: httpService.authHeader(),
                })
                this.notifications = res.data.data;
            } catch (error) {
                // errorService.displayError(error);
            }

        },

        async readAllNotification() {
            try {
                await httpService.get(endpoint.notification + '/read-all');
                this.notifications = [];
            } catch (error) {
                errorService.displayError(error);
            }

        },

        async readNotification(id) {
            try {
                await httpService.get(endpoint.notification + `/read/${id}`);
                this.getUnreadNotification();
            } catch (error) {
                errorService.displayError(error);
            }

        },

        notificationTime(timeString) {
            return moment(timeString).format('dddd, DD MMMM hh:mm');
        },

        notificationAction(data) {
            this.readNotification(data.id);
            if (permissonService.hasRole('admin')) {
                if (data.type == "App\\Notifications\\NewWithdrawNotification") {
                    this.$router.push({
                        name: 'withdraw'
                    });
                } else if (data.type == "App\\Notifications\\NewVoucherPurchaseNotification") {
                    this.$router.push({
                        name: 'voucher-purchase'
                    });
                } else {
                    this.$router.push({
                        name: 'share.purchase'
                    });
                }
            } else {
                if (data.type == "App\\Notifications\\WithdrawVerificationNotification") {
                    this.$router.push({
                        name: 'user.withdraw'
                    });
                } else if (data.type == "App\\Notifications\\VoucherPurchaseVerificationNotification") {
                    this.$router.push({
                        name: 'user.voucher-purchase'
                    });
                } else {
                    this.$router.push({
                        name: 'user.share-purchase-history'
                    });
                }
            }

        },

        beforeUnmount() {
            clearInterval(this.timer);
        }
    },
    computed: {
    }
}
</script>

<style>
</style>