<template>
    <div class="me-1 mt-2 header-item">
        <h4 v-if="!load">{{ globalService.toIdr(wallet.saldo) }}</h4>
        <div style="width: 200px;" v-else >
            <ParagraphShimmer :is-loading="true" :lines="1" />
        </div>
    </div>
</template>

<script>
import { endpoint } from '../host';
import globalService from '../services/global-service';
import httpService from '../services/http-service';
import { ParagraphShimmer } from "vue3-shimmer";

export default {
    components: {
        ParagraphShimmer,
    },
    created() {
        this.showWallet();
    },
    watch: {
        $route(to, from) {
            this.showWallet();
        }
    },
    data (){
        return {
            globalService: globalService,
            load: false,
            wallet: null,
        }
    },
    methods: {
        async showWallet() {
            this.load = true;
            let res = await httpService.get(endpoint.wallet + '/my-wallet');
            this.wallet = res.data.data;
            this.load = false;
        },
    }
}
</script>

<style>
</style>