<template>
  <div id="layout-wrapper">
    <header id="page-topbar">
      <div class="layout-width">
        <div class="navbar-header">
          <div class="d-flex">
            <div class="navbar-brand-box horizontal-logo">
              <router-link :to="{ name: urlDash }" class="logo logo-dark">
                <span class="logo-sm">
                  <img src="/assets/images/logo-light-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="/assets/images/logo-dark.svg" alt="" height="17" />
                </span>
              </router-link>

              <router-link :to="{ name: urlDash }" class="logo logo-light">
                <span class="logo-sm">
                  <img src="/assets/images/logo-light-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="/assets/images/logo-light.svg" alt="" height="17" />
                </span>
              </router-link>
            </div>

            <button type="button" class="
                  btn btn-sm
                  px-3
                  fs-16
                  header-item
                  vertical-menu-btn
                  topnav-hamburger
                " id="topnav-hamburger-icon">
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <!-- App Search-->
            <form class="app-search d-none d-md-block">
              <div class="position-relative">
                <input type="text" class="form-control" placeholder="Search..." autocomplete="off" id="search-options"
                  value="" />
                <span class="mdi mdi-magnify search-widget-icon"></span>
                <span class="
                      mdi mdi-close-circle
                      search-widget-icon search-widget-icon-close
                      d-none
                    " id="search-close-options"></span>
              </div>
              <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                <div data-simplebar style="max-height: 320px">
                  <!-- item-->
                  <div class="dropdown-header">
                    <h6 class="text-overflow text-muted mb-0 text-uppercase">
                      Recent Searches
                    </h6>
                  </div>

                  <div class="dropdown-item bg-transparent text-wrap">
                    <a href="index.html" class="btn btn-soft-secondary btn-sm btn-rounded">how to setup <i
                        class="mdi mdi-magnify ms-1"></i></a>
                    <a href="index.html" class="btn btn-soft-secondary btn-sm btn-rounded">buttons <i
                        class="mdi mdi-magnify ms-1"></i></a>
                  </div>
                  <!-- item-->
                  <div class="dropdown-header mt-2">
                    <h6 class="text-overflow text-muted mb-1 text-uppercase">
                      Pages
                    </h6>
                  </div>

                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="
                          ri-bubble-chart-line
                          align-middle
                          fs-18
                          text-muted
                          me-2
                        "></i>
                    <span>Analytics Dashboard</span>
                  </a>

                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="
                          ri-lifebuoy-line
                          align-middle
                          fs-18
                          text-muted
                          me-2
                        "></i>
                    <span>Help Center</span>
                  </a>

                  <!-- item-->
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                    <i class="
                          ri-user-settings-line
                          align-middle
                          fs-18
                          text-muted
                          me-2
                        "></i>
                    <span>My account settings</span>
                  </a>

                  <!-- item-->
                  <div class="dropdown-header mt-2">
                    <h6 class="text-overflow text-muted mb-2 text-uppercase">
                      Members
                    </h6>
                  </div>

                  <div class="notification-list">
                    <!-- item -->
                    <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                      <div class="d-flex">
                        <img src="assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                          alt="user-pic" />
                        <div class="flex-1">
                          <h6 class="m-0">Angela Bernier</h6>
                          <span class="fs-11 mb-0 text-muted">Manager</span>
                        </div>
                      </div>
                    </a>
                    <!-- item -->
                    <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                      <div class="d-flex">
                        <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                          alt="user-pic" />
                        <div class="flex-1">
                          <h6 class="m-0">David Grasso</h6>
                          <span class="fs-11 mb-0 text-muted">Web Designer</span>
                        </div>
                      </div>
                    </a>
                    <!-- item -->
                    <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                      <div class="d-flex">
                        <img src="assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs"
                          alt="user-pic" />
                        <div class="flex-1">
                          <h6 class="m-0">Mike Bunch</h6>
                          <span class="fs-11 mb-0 text-muted">React Developer</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="text-center pt-3 pb-1">
                  <a href="pages-search-results.html" class="btn btn-primary btn-sm">View All Results <i
                      class="ri-arrow-right-line ms-1"></i></a>
                </div>
              </div>
            </form>
          </div>

          <div class="d-flex align-items-center">

            <!-- saldo header -->
            <SaldoHeader v-if="hasRole('investor')" />

            <div class="ms-1 header-item d-none d-sm-flex">
              <button type="button" class="
                    btn btn-icon btn-topbar btn-ghost-secondary
                    rounded-circle
                    light-dark-mode
                  ">
                <i class="bx bx-moon fs-22"></i>
              </button>
            </div>

            <!-- notification -->
            <Notification />

            <div class="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="d-flex align-items-center">
                  <img class="rounded-circle header-profile-user" src="/assets/images/users/user-dummy-img.jpg"
                    alt="Header Avatar" />
                  <span class="text-start ms-xl-2">
                    <span class="
                          d-none d-xl-inline-block
                          ms-1
                          fw-medium
                          user-name-text
                        ">{{  name  }}</span>
                    <span class="
                          d-none d-xl-block
                          ms-1
                          fs-12
                          text-muted
                          user-name-sub-text
                        ">{{  level  }}</span>
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header">Selamat datang {{  name  }}!</h6>
                <router-link class="dropdown-item" :to="{ name: 'user.edit-password' }"><i
                    class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Ganti Password</span>
                </router-link>
                <button class="dropdown-item" @click="logout">
                  <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle" data-key="t-logout">Logout</span>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                  <!-- item-->
                  <h6 class="dropdown-header">Selamat datang {{  name  }}!</h6>
                  <router-link class="dropdown-item" :to="{ name: 'user.edit-password' }"><i
                      class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                    <span class="align-middle">Ganti Password</span>
                  </router-link>
                  <button class="dropdown-item" @click="logout">
                    <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                    <span class="align-middle" data-key="t-logout">Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="app-menu navbar-menu">
      <div class="navbar-brand-box">
        <router-link :to="{ name: urlDash }" class="logo logo-dark">
          <span class="logo-sm">
            <img src="/assets/images/logo-light-sm.png" alt="" height="22" />
          </span>

          <span class="logo-lg">
            <img src="/assets/images/logo-dark.png" alt="" height="25" />
          </span>
        </router-link>
        <router-link :to="{ name: urlDash }" class="logo logo-light">
          <span class="logo-sm">
            <img src="/assets/images/logo-light-sm.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="/assets/images/logo-light.png" alt="" height="25" />
          </span>
        </router-link>
        <button type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            " id="vertical-hover">
          <i class="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <SideMenu v-if="hasRole('admin')" />
        <InvestorSideMenu v-else />
      </div>
    </div>

    <div class="vertical-overlay"></div>

    <div class="main-content">
      <router-view @counter-event="() => incrCounter()"></router-view>
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              2022 - {{  currentYear  }} © {{  appName  }}
            </div>
            <div class="col-sm-6"></div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>


<script>
import SideMenu from "./SideMenu.vue";
import Swal from "sweetalert2";
import moment from "moment";
import permissonService from '../services/permisson-service';
import InvestorSideMenu from './InvestorSideMenu.vue';
import Notification from "../components/Notification.vue";
import SaldoHeader from "../components/SaldoHeader.vue";
export default {
  components: {
    SideMenu,
    InvestorSideMenu,
    Notification,
    SaldoHeader
  },
  data() {
    return {
      name: null,
      level: null,
      urlDash: null
    };
  },
  created() {
    var scripts = ["/assets/js/app.js"];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    this.name = permissonService.user().name;
    if (JSON.parse(localStorage.getItem('roles'))[0] == 'admin') {
      this.urlDash = 'home';
      this.level = 'admin';
    } else {
      this.urlDash = 'user.home';
      this.level = 'investor';
    }
  },
  computed: {
    currentYear() {
      return moment().year();
    },
    appName() {
      return process.env.VUE_APP_TITLE;
    },
  },
  methods: {
    async logout() {
      Swal.fire({
        title: "Konfirmasi !",
        text: "Apakah anda yakin ingin logout dari aplikasi?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#405189",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, logout",
        cancelButtonText: "Tidak, batalkan",
        buttonsStyling: true,
      }).then((isConfirm) => {
        if (isConfirm.value === true) {
          localStorage.clear();
          this.$router.push({
            name: "login",
          });
        }
      });
    },

    incrCounter() {
      console.log('oke');
    },

    hasRole(name) {
      return permissonService.hasRole(name);
    },
  },
};
</script>

<style>
</style>