<template>
  <div v-if="state(this.$route.path) === 'public'">
    <router-view />
  </div>
  <div v-if="state(this.$route.path) === 'full'">
    <router-view />
  </div>
  <div v-if="state(this.$route.path) === 'auth'">
    <DashboardLayouts />
  </div>
</template>


<script>
import DashboardLayouts from './layouts/DashboardLayouts.vue';

export default {
  components: {
    DashboardLayouts
  },
  created() {
  },
  methods: {
    state(path) {
      // console.log('A', this.$route.path);
      // console.log('B', path);
      let paths = this.$route.path.split("/");
      // console.log(paths);
      if (paths[1] != 'admin' && paths[1] != 'user') {
        return 'public'
      } else {
        if (paths[2] == "login" || paths[2] == "register" || paths[1] == "validate") {
          return "full";
        } else {
          return "auth";
        }
      }
    },
  },
};
</script>

<style>
.rotate-down {
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.un-rotate {
  transition: all 0.3s ease;
  transform: rotate(0deg);
}
</style>