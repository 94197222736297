export default [
    {
        path: '/user',
        name: 'user.home',
        component: () => import("../userViews/home/Index.vue")
    },
    {
        path: '/user/register',
        name: 'user.register',
        component: () => import("../userViews/auth/Register.vue")
    },
    {
        path: '/user/transaction',
        name: 'user.transaction',
        component: () => import("../userViews/transaction/Transaction.vue")
    },
    {
        path: '/user/share',
        name: 'user.share',
        component: () => import("../userViews/share/Share.vue")
    },
    {
        path: '/user/share-distribution',
        name: 'user.share-distribution',
        component: () => import("../userViews/shareDistribution/ShareDistribution.vue")
    },
    {
        path: '/user/share-purchase',
        name: 'user.share-purchase',
        component: () => import("../userViews/sharePurchase/SharePurchase.vue")
    },
    {
        path: '/user/share-purchase-history',
        name: 'user.share-purchase-history',
        component: () => import("../userViews/sharePurchase/SharePurchaseHistory.vue")
    },
    {
        path: '/user/share-purchase-detail/:id',
        name: 'user.share-purchase-detail',
        component: () => import("../userViews/sharePurchase/SharePurchaseDetail.vue")
    },
    {
        path: '/user/certificate',
        name: 'user.certificate',
        component: () => import("../userViews/certificate/Certificate.vue")
    },
    {
        path: '/user/transaction',
        name: 'user.transaction',
        component: () => import("../userViews/transaction/Transaction.vue")
    },
    {
        path: '/user/withdraw',
        name: 'user.withdraw',
        component: () => import("../userViews/withdraw/Withdraw.vue")
    },
    {
        path: '/user/withdraw/:id',
        name: 'user.withdraw-detail',
        component: () => import("../userViews/withdraw/WithdrawDetail.vue")
    },
    {
        path: '/user/voucher-purchase',
        name: 'user.voucher-purchase',
        component: () => import("../userViews/voucherPurchase/VoucherPurchase.vue")
    },
    {
        path: '/user/voucher-purchase/:id',
        name: 'user.voucher-purchase-detail',
        component: () => import("../userViews/voucherPurchase/VoucherPurchaseDetail.vue")
    },
    {
        path: '/user/profile',
        name: 'user.profile',
        component: () => import("../userViews/auth/Profile.vue")
    },
    {
        path: '/user/edit-password',
        name: 'user.edit-password',
        component: () => import("../userViews/auth/EditPassword.vue")
    },
    {
        path: '/user/edit-bank-account',
        name: 'user.edit-bank-account',
        component: () => import("../userViews/auth/EditBankAccount.vue")
    },
    {
        path: '/user/edit-heir',
        name: 'user.edit-heir',
        component: () => import("../userViews/auth/EditHeir.vue")
    },
]