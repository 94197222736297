export default [
    {
        path: '/',
        name: 'public.home',
        component: () => import("../views/auth/Login.vue")
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../views/auth/Login.vue")
    },
    {
        path: '/password/forgot-password',
        name: 'password.forgot-password',
        component: () => import("../userViews/auth/ForgotPassword.vue")
    },
    {
        path: '/password/reset',
        name: 'password.reset',
        component: () => import("../userViews/auth/ResetPassword.vue")
    },
    {
        path: '/validate/certificate/:share_number',
        name: 'public.validate.certificate',
        component: () => import("../publicViews/ValidateCertificate.vue")
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'any',
        component: () => import("../publicViews/404.vue")
    },
    {
        path: '/404',
        name: '404',
        component: () => import("../publicViews/404.vue")
    },
];