import moment from "moment";

export default new class GlobalService {

    /**
     * 
     * @param {String} dateString 
     * @returns 
     */
    dateFormat(dateString) {
        return moment(dateString).format('dddd, DD MMMM Y');
    }

    /**
     * 
     * @param {String} dateString 
     * @returns 
     */
    dateFormat2(dateString) {
        return moment(dateString).format('dddd, DD MMMM Y HH:MM');
    }

    /**
     * toIdr
     * @param {*} value 
     * @returns 
     */
    toIdr(value) {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
        }).format(value);
    }

    /**
     * conver number to month name
     * @param {string} monthNumber 
     * @returns 
     */
    toMonthName(monthNumber) {
        return moment().month(monthNumber).format("MMMM")
    }

}