<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>
    <!-- admin menu -->
    <ul class="navbar-nav" id="navbar-nav">

      <li class="menu-title"><span data-key="t-menu">Menu</span></li>

      <li class="nav-item">
        <router-link class="nav-link menu-link" :to="{ name: 'user.home' }">
          <i class="ri-dashboard-2-line"></i>
          <span data-key="t-home">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.share' }">
          <i class="ri-medal-2-line"></i>
          <span data-key="t-share">Sahamku</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share_purchase create')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.share-purchase' }">
          <i class="ri-shopping-cart-2-line"></i>
          <span data-key="t-share">Beli Saham</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share_purchase view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.share-purchase-history' }">
          <i class="ri-time-line"></i>
          <span data-key="t-investor">Riwayat Pembelian</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share_distribution view')">
        <router-link
          class="nav-link menu-link"
          :to="{ name: 'user.share-distribution' }"
        >
          <i class="ri-stackshare-line"></i>
          <span data-key="t-income-statement">Bagi Hasil</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.certificate' }">
          <i class="ri-profile-line"></i>
          <span data-key="t-user">Sertifikat</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('withdraw view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.withdraw' }">
          <i class="ri-download-2-line"></i>
          <span data-key="t-all-reimbursement">WithDraw</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('voucher_purchase view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.voucher-purchase' }">
          <i class="ri-ticket-line"></i>
          <span data-key="t-voucher-purchase">Kupon Belanja</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('transaction view')">
        <router-link class="nav-link menu-link" :to="{ name: 'user.transaction' }">
          <i class="ri-shopping-bag-3-line"></i>
          <span data-key="t-all-reimbursement">Riwayat Transaksi</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link menu-link" :to="{ name: 'user.profile' }">
          <i class="ri-user-line"></i>
          <span data-key="t-all-reimbursement">Akun</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import permissonService from '../services/permisson-service';

export default {
  name: "SideMenu",
  data() {
    return {
      permission : permissonService,
    };
  },
  mounted() {
  },
  created() {},
  methods: {},
};
</script>

<style>
</style>