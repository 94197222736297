import axios from "axios";
import route from "../router";

export default new class HttpService {
    /**
     * 
     * @param {*} url 
     * @param {*} param 
     * @returns 
     */
    async get(url, config) {
        return new Promise((resolve, reject) => {
            axios.get(url, config ? config : {
                headers: this.authHeader(),
            }).then((r) => {
                resolve(r);
            }).catch((e) => {
                if (e.response) {
                    if (e.response.status == 401 || e.response.status == 403) {
                        localStorage.clear();
                        route.push({
                            name: 'login',
                            params: {
                                reload: true
                            },
                        });
                    }
                }
                reject(e);
            })
        });
    }

    /**
     * 
     * @param {*} url 
     * @param {*} body 
     * @param {*} config 
     * @returns 
     */
    async post(url, body, config) {
        return new Promise((resolve, reject) => {
            axios.post(url, body, config ? config : {
                headers: this.authHeader(),
            }).then((r) => {
                resolve(r);
            }).catch((e) => {
                // if (e.response) {
                //     if (e.response.status == 401) {
                //         localStorage.clear();
                //         route.push({
                //             name: 'login',
                //             params: {
                //                 reload: true
                //             },
                //         });
                //     }
                // }
                reject(e);
            });
        })
    }

    /**
     * 
     * @param {*} url 
     * @param {*} config 
     * @returns 
     */
    async delete(url, config) {
        return new Promise((resolve, reject) => {
            axios.delete(url, config ? config : {
                headers: this.authHeader(),
            }).then((r) => {
                resolve(r);
            }).catch((e) => {
                if (e.response.status == 401) {
                    localStorage.clear();
                    route.push({
                        name: 'login',
                        params: {
                            reload: true
                        },
                    });
                }
                reject(e);
            });
        });
    }

    authHeader() {
        let token = localStorage.getItem('token');
        if (token) {
            return {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
            };
        }
        return {};
    }

    /**
     * 
     * @param {Object} params 
     */
    generateEndpointQuery(endpoint, params) {
        let result = [];
        for (const key in params) {
            if (params[key] != '' && params[key] != null) {
                result.push([key, params[key]].join('='));
            }
        }
        return endpoint + '?' + result.join('&');
    }
}