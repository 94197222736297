export default [
    {
        path: '/admin',
        name: 'home',
        component: () => import("../views/home/Index.vue")
    },
    {
        path: '/admin/role',
        name: 'role',
        component: () => import("../views/role/Role.vue")
    },
    {
        path: '/admin/role-form/:id?',
        name: 'role.form',
        component: () => import("../views/role/RoleForm.vue")
    },
    {
        path: '/admin/share-period',
        name: 'share.period',
        component: () => import("../views/sharePeriod/SharePeriod.vue")
    },
    {
        path: '/admin/share-period/form',
        name: 'share.period.form',
        component: () => import("../views/sharePeriod/SharePeriodForm.vue")
    },
    {
        path: '/admin/income-statement',
        name: 'income.statement',
        component: () => import("../views/incomeStatement/IncomeStatement.vue")
    },
    {
        path: '/admin/income-statement-form',
        name: 'income.statement.form',
        component: () => import("../views/incomeStatement/IncomeStatementForm.vue")
    },
    {
        path: '/admin/investor',
        name: 'investor',
        component: () => import("../views/investor/Investor.vue")
    },
    {
        path: '/admin/investor-form',
        name: 'investor.form',
        component: () => import("../views/investor/InvestorForm.vue")
    },
    {
        path: '/admin/investor/:id',
        name: 'investor.detail',
        component: () => import("../views/investor/InvestorDetail.vue")
    },
    {
        path: '/admin/investor-change-email/:id',
        name: 'investor.change-email',
        component: () => import("../views/investor/ChangeEmail.vue")
    },
    {
        path: '/admin/marketing',
        name: 'marketing',
        component: () => import("../views/marketing/Marketing.vue")
    },
    {
        path: '/admin/marketing-form',
        name: 'marketing.form',
        component: () => import("../views/marketing/MarketingForm.vue")
    },
    {
        path: '/admin/share-purchase',
        name: 'share.purchase',
        component: () => import("../views/sharePurchase/SharePurchase.vue")
    },
    {
        path: '/admin/share-purchase/:id',
        name: 'share.purchase.detail',
        component: () => import("../views/sharePurchase/SharePurchaseDetail.vue")
    },
    {
        path: '/admin/share-distribution',
        name: 'share.distribution',
        component: () => import("../views/shareDistribution/ShareDistribution.vue")
    },
    {
        path: '/admin/withdraw',
        name: 'withdraw',
        component: () => import("../views/withdraw/Withdraw.vue")
    },
    {
        path: '/admin/withdraw/:id',
        name: 'withdraw.detail',
        component: () => import("../views/withdraw/WithdrawDetail.vue")
    },
    {
        path: '/admin/voucher-purchase',
        name: 'voucher-purchase',
        component: () => import("../views/voucherPurchase/VoucherPurchase.vue")
    },
    {
        path: '/admin/voucher-purchase-detail/:id',
        name: 'voucher-purchase-detail',
        component: () => import("../views/voucherPurchase/VoucherPurchaseDetail.vue")
    },
    {
        path: '/admin/transaction',
        name: 'transaction',
        component: () => import("../views/transaction/Transaction.vue")
    },
    {
        path: '/admin/share',
        name: 'share',
        component: () => import("../views/share/Share.vue")
    },
    {
        path: '/admin/share-transfer/:id',
        name: 'share-transfer',
        component: () => import("../views/share/ShareTransfer.vue")
    },
];