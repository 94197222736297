// export var host = 'http://localhost:8000';
export var host = 'https://api-inalife.misilidik.com';

export var endpoint = {
    'certificate.download' : host + '/certificate/download',
    'index': host + '/api/v1',
    'auth': host + '/api/v1/auth',
    'login': host + '/api/v1/auth/login',
    'password': host + '/api/v1/password',
    'register': host + '/api/v1/auth/register',
    'profile': host + '/api/v1/auth/profile',
    'role': host + '/api/v1/role',
    'permission': host + '/api/v1/role/permission',
    'share': host + '/api/v1/share',
    'share.period': host + '/api/v1/share/period',
    'share.purchase': host + '/api/v1/share/purchase',
    'income.statement': host + '/api/v1/income/statement',
    'investor': host + '/api/v1/investor',
    'transaction': host + '/api/v1/transaction',
    'marketing': host + '/api/v1/marketing',
    'withdraw': host + '/api/v1/withdraw',
    'voucher-purchase': host + '/api/v1/voucher-purchase',
    'notification': host + '/api/v1/notification',
    'wallet': host + '/api/v1/wallet',
    'user': host + '/api/v1/user',
};