import Swal from "sweetalert2";

export default new class ErrorService {
    /**
     * get error message
     * @param {*} data 
     * @returns 
     */
    getErrorMassage(data) {
        let result = '';
        let error = data.errors
        Object.keys(error).forEach(element => {
            result += ' ' + error[element].join();
        });
        return result;
    }

    /**
     * 
     * @param {*} error
     * @returns 
     */
    handleError(error) {
        if ( typeof error  === 'object') {
            if ('response' in error) {
                if (error.response.status == 422) {
                    return this.getErrorMassage(error.response.data);
                } else {
                    if (error.response.data.message) {
                        return error.response.data.message;
                    } else {
                        return 'Network error';
                    }
                }
            }
        }
        return error;

    }

    /**
     * display error message to sweetalert
     * @param {*} error 
     * @returns 
     */
    displayError(error) {
        console.log(error);
        var message = this.handleError(error);
        return Swal.fire({
            title: "Error",
            text: message,
            icon: "error",
        })
    }
}