import { createRouter, createWebHistory } from "vue-router";
import user from "./user-route";
import admin from "./admin-route";
import umum from "./public-route";

const routes = [
    ...admin,
    ...user,
    ...umum,
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/admin/login', '/user/login', '/', '/news'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    let paths = to.path.split("/");
    // console.log(paths);
    if (paths[1] != 'admin' && paths[1] != 'user') {
        next();
    } else {
        if (paths[2] == "login" || paths[2] == "register") {
            next();
        } else {
            if (!loggedIn) {
                if (paths[1] == 'user') {
                    next('/login');
                } else {
                    next('/login');
                }
            } else {
                next();
            }
        }
    }
});

export default router;