export default new class PermissionService {
    /**
     * Cek permission user
     * 
     * @param {String} permission 
     * @returns 
     */
    can(permission) {
        let result = false;
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        if (permissions) {
            permissions.forEach(element => {
                if (element == permission) {
                    result = true;
                }
            });
        }
        return result;
    }

    /**
     * Cek acccess level user
     * 
     * @param {String} role 
     * @returns 
     */
    hasRole(name) {
        let result = false;
        let roles = JSON.parse(localStorage.getItem('roles'));
        if (roles) {
            roles.forEach(element => {
                if (element == name) {
                    result = true;
                }
            });
        }
        return result;
    }

    /**
     * get user data
     * 
     * @returns 
     */
     user()
     {
         return JSON.parse(localStorage.getItem('user'));
     }

     /**
      * get roles data
      * 
      * @returns 
      */
     roles()
     {
         return JSON.parse(localStorage.getItem('roles'));
     }
}