<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>
    <!-- admin menu -->
    <ul class="navbar-nav" id="navbar-nav">

      <li class="menu-title"><span data-key="t-menu">Menu</span></li>

      <li class="nav-item">
        <router-link class="nav-link menu-link" :to="{ name: 'home' }">
          <i class="ri-dashboard-2-line"></i>
          <span data-key="t-home">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('role view')">
        <router-link class="nav-link menu-link" :to="{ name: 'role' }">
          <i class="ri-shield-user-line"></i>
          <span data-key="t-share">Role</span>
        </router-link>
      </li>


      <li class="nav-item" v-if="permission.can('share_period view')">
        <router-link class="nav-link menu-link" :to="{ name: 'share.period' }">
          <i class="ri-red-packet-line"></i>
          <span data-key="t-share">Periode Saham</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('user view')">
        <router-link class="nav-link menu-link" :to="{ name: 'investor' }">
          <i class="ri-user-line"></i>
          <span data-key="t-investor">Investor</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('user view')">
        <router-link class="nav-link menu-link" :to="{ name: 'share' }">
          <i class="ri-file-paper-2-line"></i>
          <span data-key="t-user">Saham</span>
        </router-link>
      </li>

      <li class="menu-title"><span data-key="t-menu">Transaksi</span></li>

      <li class="nav-item" v-if="permission.can('share_purchase view')">
        <router-link class="nav-link menu-link" :to="{ name: 'share.purchase' }">
          <i class="ri-shopping-cart-line"></i>
          <span data-key="t-all-reimbursement">Pembelian Saham</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('income_statement view')">
        <router-link
          class="nav-link menu-link"
          :to="{ name: 'income.statement' }"
        >
          <i class="ri-funds-box-line"></i>
          <span data-key="t-income-statement">Laporan Laba Rugi</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('share_distribution view')">
        <router-link class="nav-link menu-link" :to="{ name: 'share.distribution' }">
          <i class="ri-stackshare-line"></i>
          <span data-key="t-all-reimbursement">Distribusi Saham</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('withdraw view')">
        <router-link class="nav-link menu-link" :to="{ name: 'withdraw' }">
          <i class="ri-wallet-line"></i>
          <span data-key="t-all-reimbursement">Withdraw</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('voucher_purchase view')">
        <router-link class="nav-link menu-link" :to="{ name: 'voucher-purchase' }">
          <i class="ri-ticket-line"></i>
          <span data-key="t-voucher-purchase">Kupon Belanja</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="permission.can('transaction view')">
        <router-link class="nav-link menu-link" :to="{ name: 'transaction' }">
          <i class="ri-time-line"></i>
          <span data-key="t-investor">Riwayat Transaksi</span>
        </router-link>
      </li>

    </ul>
  </div>
</template>

<script>
import permissonService from '../services/permisson-service';
export default {
  name: "SideMenu",
  data() {
    return {
      permission : permissonService,
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style>
</style>